<template>
  <div class="main-content q-pa-md">
    <q-form
      ref="generateEvaluationForm"
      @submit.prevent.stop="validateAddOrUpdateQuiz"
    >
      <Mural
        :breadcrumbs="breadcrumbs"
        :query="{ courseId: courseId, trainingId: trainingId, postId: postId }"
        route="new-content"
      >
        <FormButton titleButton="Salvar" type="submit" />
      </Mural>
      <div class="generate-evaluation col-12 row q-mt-lg">
        <FormEvaluation :questions="questions" />
        <div class="material-questions col-md-7 col-sm-12">
          <fromQuestionEvaluation
            :questions="questions"
            :quizId="quizId"
            :optionAlternatives="optionTypeAlternatives"
            @addNewQuestion="(newQuestion) => addNewTab(newQuestion)"
            @removeQuestion="(question) => removeQuestion(question)"
            @getQuestionsDatabase="
              (questionId) => getQuestionsDatabase(questionId)
            "
          />
        </div>
      </div>
      <DialogQuestion
        v-if="dialogDatabaseQuestion"
        :showDialog="dialogDatabaseQuestion"
        :dataBaseQuestions="dataBaseQuestions"
        @showQuestions="(e) => showQuestions(e)"
      />
      <q-inner-loading
        :showing="loading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      >
        <div class="collumn justify-center q-my-md">
          <div style="height: auto; width: 50px; margin: auto">
            <LoadingIcon />
          </div>
          <label>Por favor aguarde...</label>
        </div>
      </q-inner-loading>
    </q-form>
  </div>
</template>

<script>
//----COMPONENTS
import Mural from "@/components/Mural.vue";
import FormButton from "@/components/courses/buttons/FormButton.vue";
import TabDefault from "@/components/tabs/DefaultTabs.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import FormEvaluation from "@/components/courses/training/evaluation/FormEvaluation.vue";
import FromQuestionEvaluation from "@/components/courses/training/evaluation/FromQuestionEvaluation.vue";
import DialogQuestion from "@/components/courses/dialogs/DialogQuestion.vue";
import QuestionCard from "@/components/courses/cards/QuestionCard.vue";

//----SERVICES
import GenerateEvaluationDataServices from "@/services/GenerateEvaluationDataServices";

//----QUASAR
import { date, useQuasar } from "quasar";

//----VUEJS
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "GenerateEvaluation",
  components: {
    Mural,
    FormButton,
    TabDefault,
    QuestionCard,
    LoadingIcon,
    FormEvaluation,
    FromQuestionEvaluation,
    DialogQuestion,
  },
  setup() {
    const $q = useQuasar();
    const generateEvaluationForm = ref(null);
    const breadcrumbs = ref([]);
    const router = new useRouter();
    const route = new useRoute();
    const generateEvaluationDataServices = new GenerateEvaluationDataServices();
    const courseId = route.query.courseId;
    const trainingId = route.query.trainingId;
    const postId = route.query.postId;
    const quizId = route.query.quizId;
    const evaluation = route.query.evaluation;

    let loading = ref(false);

    let dialogDatabaseQuestion = ref(false);
    let questions = ref({
      title: "",
      description: "",
      evaluation: evaluation == "true" ? true : false,
      number_attempts: 0,
      number_questions: 0,
      max_score: 0,
      approval_score: 0,
      start_date: "",
      end_date: "",
      show_score: true,
      options: [],
    });
    let questionIdCurrent = ref(null);
    let optionTypeAlternatives = ref();
    let dataBaseQuestions = ref([]);
    let isValidateForm = ref(true);

    function typeAlternatives(evaluation) {
      if (evaluation === "true") {
        breadcrumbs.value.push("Criar avaliação");
        optionTypeAlternatives.value = [
          {
            label: "Opção única",
            value: 1,
          },
          {
            label: "Múltipla escolha",
            value: 2,
          },
          {
            label: "Questão aberta",
            value: 3,
          },
        ];
      } else {
        breadcrumbs.value.push("Criar Quiz");
        optionTypeAlternatives.value = [
          {
            label: "Opção única",
            value: 1,
          },
          {
            label: "Múltipla escolha",
            value: 2,
          },
        ];
      }
    }

    function addNewTab(newQuestion) {
      if (questions.value.options.length == 0) {
        questions.value.options.push(newQuestion.options);
        return;
      }

      let lastValue =
        questions.value.options[
          Object.keys(questions.value.options)[
            Object.keys(questions.value.options).length - 1
          ]
        ];

      newQuestion.options.question.id = lastValue.question.id + 1;

      questions.value.options.push(newQuestion.options);
    }

    function showQuestions(item) {
      questions.value.options.map((option) => {
        if (option.question.id == questionIdCurrent.value) {
          option.isQuestionFromDataBase = true;
          option.question.description_question = item.description_question;
          option.question_id = item.questionId;
          option.question.questionId = item.questionId;

          option.question.option_type = item.option_type;

          option.question.options.map((option, index) => {
            option.description_option = item.options[index].description_option;
            option.correct_question = item.options[index].correct_question;
          });
        }
      });

      dialogDatabaseQuestion.value = false;
      questionIdCurrent.value = null;
    }

    onMounted(() => {
      if (quizId != null && quizId != 0) {
        _initDataLoad();
      }
      typeAlternatives(evaluation);
    });

    function _initDataLoad() {
      loading.value = true;
      generateEvaluationDataServices
        .getQuiz(courseId, trainingId, quizId)
        .then((response) => {
          if (response.data.options.length > 0 || response.data != null) {
            _orderQuestions(response.data.options);

            questions.value.options = response.data.options;
            questions.value.title = response.data.title;
            questions.value.description = response.data.description;
            questions.value.evaluation = response.data.evaluation;
            questions.value.number_attempts = response.data.number_attempts;
            questions.value.number_questions = response.data.number_questions;
            questions.value.max_score = response.data.max_score;
            questions.value.approval_score = response.data.approval_score;
            questions.value.start_date = date.formatDate(
              response.data.start_date,
              "YYYY-MM-DD"
            );
            questions.value.end_date = date.formatDate(
              response.data.end_date,
              "YYYY-MM-DD"
            );
          }

          loading.value = false;
        })
        .catch((error) => {
          loading.value = false;
        });
    }

    async function removeQuestion(question) {
      if (question.removeOrAnulated) {
        await generateEvaluationDataServices
          .anulatedQuestion(question.id)
          .then((response) => {
            if (response.data) {
              _notify("Questão anulada com sucesso", "green-9");
            } else {
              _notify("Erro ao anular questão");
            }
          })
          .catch((error) => {
            _notify("Não foi possivel anular a questão");
          });
      } else {
        let array = questions.value.options;
        let data = array.filter((element) => {
          return element.question.id !== question.id;
        });

        questions.value.options = data;
        _orderQuestions(questions.value.options);
        if (questions.value.options.length == 0) {
          questions.value.options = [];
        }
      }
    }

    function _orderQuestions(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].question.id = i + 1;
      }
    }

    async function validateAddOrUpdateQuiz() {
      isValidateForm.value = true;
      await generateEvaluationForm.value.validate().catch(() => {
        _notify(
          "Para salvar uma avaliação você tem que cadastrar pelo menos uma questão."
        );
        isValidateForm.value = false;
        return;
      });

      if (questions.value.options.length <= 0) {
        _notify(
          "Para salvar uma avaliação você tem que cadastrar pelo menos uma questão."
        );
        isValidateForm.value = false;
        return;
      }

      if (questions.value.number_questions > questions.value.options.length) {
        _notify(
          "Você precisa cadastrar o mesmo número de questões sinalizadas"
        );
        isValidateForm.value = false;
        return;
      }

      _validateForm();

      if (isValidateForm.value) {
        let validateQuestion = await _saveQuestions();

        if (validateQuestion) {
          _addOrUpdateQuiz();
        }
      }
    }

    async function _validateForm() {
      let numberOfFalseinOptions = 0;
      let isSucceed = questions.value.options.map((option) => {
        numberOfFalseinOptions = _validateOptionsQuestions(
          option.question.options
        );

        return option.weight;
      });

      if (isSucceed.includes(true)) {
        isValidateForm.value = false;
        _notify(
          "Para salvar uma avaliação você tem que informar o peso de todas as questões."
        );
        return false;
      }

      let optionType = [];
      questions.value.options.forEach((option) => {
        optionType.push(option.question.option_type);
      });

      if (
        optionType[optionType.length - 1] == 1 ||
        optionType[optionType.length - 1] == 2
      ) {
        if (numberOfFalseinOptions) {
          isValidateForm.value = false;
          _notify("Para salvar a avaliação, informe a questão correta.");
          return false;
        }
      }

      return true;
    }

    function _validateOptionsQuestions(options) {
      let falseQuestion = 0;
      let optionQuestionLength = 0;
      options.forEach((option) => {
        if (option.correct_question == false) {
          falseQuestion += 1;
        }
        if (option.description_option == "") {
          optionQuestionLength += 1;
        }
      });
      return falseQuestion >= 5 || optionQuestionLength >= 5;
    }

    async function _saveQuestions() {
      loading.value = true;
      for (let i = 0; i < questions.value.options.length; i++) {
        if (questions.value.options[i].question_id == 0) {
          let result = await generateEvaluationDataServices
            .saveQuestion(questions.value.options[i].question)
            .catch((error) => {
              loading.value = false;
              alert(error.response.data.toString());
              return false;
            });
          questions.value.options[i].question_id = result.data;
        } else {
          generateEvaluationDataServices
            .updateQuestion(questions.value.options[i].question)
            .catch((error) => {
              loading.value = false;
              alert(error.response.data.toString());
              return false;
            });
        }
      }

      return true;
    }

    async function _addOrUpdateQuiz() {
      if (quizId != null && quizId != 0) {
        _updateQuiz();
      } else {
        _addQuiz();
      }
    }

    async function _updateQuiz() {
      await generateEvaluationDataServices
        .updateQuiz(courseId, trainingId, quizId, questions.value)
        .then((response) => {
          loading.value = false;
          router.push({
            name: "new-content",
            query: { courseId: courseId, trainingId: trainingId },
          });
        })
        .catch((error) => {
          loading.value = false;
          alert(error.response.data);
        });
    }

    async function _addQuiz() {
      await generateEvaluationDataServices
        .saveQuiz(courseId, trainingId, questions.value)
        .then((response) => {
          loading.value = false;
          router.push({
            name: "new-content",
            query: { courseId: courseId, trainingId: trainingId },
          });
        })
        .catch((error) => {
          loading.value = false;
          alert(error.response.data);
        });
    }

    async function getQuestionsDatabase(questionId) {
      loading.value = true;
      let listQuestionDatabase =
        await generateEvaluationDataServices.getQuestionsStorage();
      loading.value = false;

      dataBaseQuestions.value = listQuestionDatabase.data;

      dialogDatabaseQuestion.value = true;
      questionIdCurrent.value = questionId;

      loading.value = false;
    }

    function selectAwnser(option, question) {
      let options = question.question.options;

      for (let index = 0; index < options.length; index++) {
        if (options[index].id == undefined) {
          if (options[index].questionOptionId == option.questionOptionId) {
            options[index].correct_question = true;
          } else {
            options[index].correct_question = false;
          }
        } else {
          if (options[index].id == option.id) {
            options[index].correct_question = true;
          } else {
            options[index].correct_question = false;
          }
        }
      }
    }

    function _notify(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    return {
      breadcrumbs,
      questions,
      optionTypeAlternatives,
      loading,
      dataBaseQuestions,
      dialogDatabaseQuestion,
      addNewTab,
      courseId,
      trainingId,
      postId,
      quizId,
      validateAddOrUpdateQuiz,
      removeQuestion,
      getQuestionsDatabase,
      showQuestions,
      selectAwnser,

      generateEvaluationForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  label {
    font-style: normal;
  }

  .generate-evaluation {
    overflow-y: auto;
    height: 75vh;
    padding: 0 1%;
  }

  .material-questions {
  }
}
</style>
