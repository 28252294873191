<template>
  <div class="material-profile col-md-5 col-sm-12 q-pa-sm">
        <div class="flex row items-center justify-between">
          <div class="col-7 flex column">
            <label class="title-info q-mt-md">Título</label>
            <q-input
              v-model="questions.title"
              bg-color="grey-3"
              dense
              placeholder="Título"
              color="default-pink"
              input-style="border-radius: 8px;"
              type="text"
              outlined
              lazy-rules
              :rules="[(val) => val != '' || 'Campo obrigatório']"
            />
          </div>
          <div class="form-input column col-4 items-center">
            <label class="title-info q-mt-md">Aluno visualizar nota</label>
            <div>
              <q-toggle v-model="questions.show_score" color="green" />
            </div>
          </div>
        </div>

        <div class="row col-12 q-mt-md items-end">
          <div class="col-5">
            <label class="title-info">Pontuação de Aprovação</label>
            <q-input
              v-model.number="questions.approval_score"
              bg-color="grey-3"
              dense
              color="default-pink"
              aria-placeholder="Número de tentativa"
              input-style="border-radius: 8px;"
              type="number"
              outlined
              lazy-rules
              :rules="[(val) => val != 0 || 'Campo obrigatório']"
            />
          </div>
          <div class="col-5 q-ml-md">
            <label class="title-info">Pontuação Máxima</label>
            <q-input
              v-model.number="questions.max_score"
              bg-color="grey-3"
              dense
              color="default-pink"
              aria-placeholder="Número de tentativa"
              input-style="border-radius: 8px;"
              type="number"
              outlined
              lazy-rules
              :rules="[(val) => val != 0 || 'Campo obrigatório']"
            />
          </div>
        </div>
        <div class="row col-12 q-mt-md items-end">
          <div class="col-5">
            <label class="title-info">Número de Tentativas</label>
            <q-input
              v-model.number="questions.number_attempts"
              bg-color="grey-3"
              dense
              color="default-pink"
              aria-placeholder="Número de tentativa"
              input-style="border-radius: 8px;"
              type="number"
              outlined
              lazy-rules
              :rules="[(val) => val != 0 || 'Campo obrigatório']"
            />
          </div>
          <div class="col-5 q-ml-md">
            <label class="title-info">Número de Questões</label>
            <q-input
              v-model.number="questions.number_questions"
              bg-color="grey-3"
              dense
              color="default-pink"
              aria-placeholder="Número de tentativa"
              input-style="border-radius: 8px;"
              type="number"
              outlined
              lazy-rules
              :rules="[(val) => val != 0 || 'Campo obrigatório']"
            />
          </div>
        </div>
        <div class="row col-12 q-mt-md items-end">
          <div class="col-12">
            <label class="title-info">Período</label>
          </div>
          <div class="col-5">
            <q-input
              v-model="questions.start_date"
              bg-color="grey-3"
              dense
              color="default-pink"
              input-style="border-radius: 8px;"
              outlined
              type="date"
              :rules="[(val) => val != '' || 'Campo obrigatório']"
            >
            </q-input>
          </div>
          <div class="col-5 q-ml-md">
            <q-input
              v-model="questions.end_date"
              bg-color="grey-3"
              dense
              color="default-pink"
              input-style="border-radius: 8px;"
              outlined
              type="date"
              lazy-rules
              :rules="[(val) => val != '' || 'Campo obrigatório']"
            >
            </q-input>
          </div>
        </div>
        <div class="col-12 row q-mt-md">
          <div class="col-12">
            <label class="title-info">Orientações</label>
          </div>
          <div class="col-12">
            <q-input
              bg-color="grey-3"
              color="default-pink"
              v-model="questions.description"
              dense
              outlined
              rounded
              counter
              maxlength="200"
              type="textarea"
              lazy-rules
              :rules="[(val) => val != '' || 'Campo obrigatório']"
            />
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'FormEvaluation',
  props:{
    questions:Object
  }
}
</script>

<style lang="scss" scoped>
.material-profile {
    border: 1px solid #e3e8eb;
    border-radius: 20px;
  }
.title-info {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }
</style>
